<template>
	<div class="order_wrap fixed_wrap customer_area">
		<div class="message_box mt-3">
			<template
				v-if="
					guideData !== null &&
						(guideData.title !== null || guideData.msg !== null)
				"
			>
				<h1 class="tit_page" v-if="guideData.title !== null">
					{{ guideData.title }}
				</h1>
				<div class="txt_body1" v-html="guideData.msg"></div>
			</template>
			<template v-else>
				<h1 class="tit_page">
					{{ this.$i18n.t('common.error.guide.wrongAccess') }}
				</h1>
				<div class="txt_body1">
					{{ this.$i18n.t('common.error.guide.personInCharge') }}
				</div>
			</template>
		</div>
	</div>
</template>

<script>
/**
 * 공통으로 사용할 가이드 페이지
 */
import { registerCallbackToApp } from '@/utils/navigation'

export default {
	name: 'Guide',
	data() {
		return {
			guideData: null
		}
	},

	created() {
		this.initAppPage()
	},

	mounted() {
		this.guideData = this.$store.getters['common/getGuideData']
	},

	methods: {
		initAppPage() {
			registerCallbackToApp({})
		}
	}
}
</script>
